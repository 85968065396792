import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
import './App.css';
import logo from './logo.png';
import Auth from './main/Auth';
import Login from './top/Login';
import Signup from './top/Signup';


const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#70bb77',
      main: '#4DAA56',
      dark: '#35763c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#33c9dc',
      main: '#00bcd4',
      dark: '#00894',
      contrastText: '#000',
    },
  },
});

function App() {
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");


  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        {token ?
          <>
            <Auth token={token} email={email} />
          </>
          :
          <>
            <header className="App-header">
              <img src={logo} className="App-logo" alt="logo" />
            </header>
            <Signup setToken={setToken} email={email} setEmail={setEmail} />
            <Login setToken={setToken} email={email} setEmail={setEmail} />
          </>
        }
      </div >
      {/* <div>
        <h2>Your Token is : {token}</h2>
      </div> */}
    </MuiThemeProvider>
  );
}

export default App;
