import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { Add, Drafts } from "@material-ui/icons";
import React, { useState } from "react";
import MakeUser from "../user/MakeUser";
import logo_icon from './logo_icon.png';
import './Navi.css';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #4DAA56',
    maxHeight: "40vh",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function CustomizedMenus({ user, baseNumber, setBaseNumber }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [toMakeBaseUser, setToMakeBaseUser] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = (event, index) => {
    setBaseNumber(index);
    setAnchorEl(null);
  };

  const handleMakeBaseUserClose = () => {
    setToMakeBaseUser(null);
  };

  const handleToMakeBaseUser = (e) => {
    setToMakeBaseUser(e.currentTarget);
  }

  const meishiBaseList = () => {
    const meishiBases = user.meishi_base;
    let lists = []
    for (let meishiBase of meishiBases) {
      lists.push(
        meishiBase
      )
    }
    return lists;
  }

  return (
    <div>
      <Button
        className="navi__button"
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {user.email}
      </Button>

      {/* デバック用 ベースナンバーの表示 */}
      {/* <br />
      {baseNumber} */}
      {/* デバック用 ベースナンバーの表示 */}

      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={handleToMakeBaseUser}>
          <ListItemIcon>
            <Add fontSize="default" />
          </ListItemIcon>
          <ListItemText primary="新しい名刺ベース" />
        </StyledMenuItem>
        {meishiBaseList().map(
          (meishiBase, index) => (
            <StyledMenuItem
              selected={index === baseNumber}
              onClick={(event) => handleMenuItemClick(event, index)}
              dense="true"
            >
              <ListItemIcon>
                <Drafts fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={meishiBase.uuid} />
            </StyledMenuItem>
          )
        )}
      </StyledMenu>
      <Dialog open={toMakeBaseUser} onClose={handleMakeBaseUserClose} aria-labelledby="form-dialog-title">
        <MakeUser user={user} />
      </Dialog>
    </div>
  );
}

function Navi({ user, baseNumber, setBaseNumber }) {
  return (
    <div className="navi">
      <div className="navi__left">
        <Button className="navi__button">設定</Button>
      </div>
      <div>
        <img className="navi__logo" src={logo_icon} alt="navi-logo" />
      </div>
      <div className="navi__right">
        <CustomizedMenus user={user} baseNumber={baseNumber} setBaseNumber={setBaseNumber} />
      </div>
    </div>
  )
}

export default Navi
