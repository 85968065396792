import { React, useEffect, useState } from "react";
import axios from "../auth/axios";
import requests from "../auth/requests";
import User from "../user/User";
import Main from "./Main";
import Navi from "./Navi";

function Auth({ token, email }) {
    const [user, setUser] = useState({
        email: "test_test@test.com",
        uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        meishi_base: [
            // {
            //     uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            //     user_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            //     first_name: "string",
            //     last_name: "string",
            //     company_name: "string",
            //     department: "string",
            //     position: "string",
            //     postal_code: "string",
            //     company_address: "string",
            //     phone_number: "string",
            //     cellphone_number: "string",
            //     web_url: "string",
            //     business_email: "string",
            //     meishi_card: [
            //         {
            //             uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            //             meishi_base_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            //             eos_id: "string",
            //         },
            //     ],
            // },
        ],
    });
    const [isMadeBaseUser, setIsMadeBaseUser] = useState(false);
    const [baseNumber, setBaseNumber] = useState(0);

    useEffect(() => {
        async function fetchData() {
            const request = await axios.get(requests.user + `${email}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUser(request.data);
            setIsMadeBaseUser(request.data.meishi_base.length > 0);
            return request;
        }
        fetchData();
    }, [email, token, isMadeBaseUser, baseNumber]);

    return (
        <>
            {isMadeBaseUser ? (
                <>
                    <Navi user={user} baseNumber={baseNumber} setBaseNumber={setBaseNumber} />
                    <Main user={user} baseNumber={baseNumber} />
                </>
            ) : (
                <>
                    <User user={user} />
                </>
            )}
        </>
    );
}

export default Auth;
