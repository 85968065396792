import axios from "axios";

const instance = axios.create({
  baseURL: "https://smartid-api.work/",
  withCredentials: true,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
});

instance.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
instance.defaults.headers.post["accept"] = "application/json";
instance.defaults.headers.get["Authorization"] = "Bearer ";

export default instance;
