
import { React, useState } from 'react';
import './Card.css';
import CardList from './CardList';
import MakeCard from "./MakeCard";

function Card({ user, baseNumber }) {
  const [txSuccess, setTxSuccess] = useState(false);

  return (
    <div>
      <CardList user={user} baseNumber={baseNumber} txSuccess={txSuccess} />
      <MakeCard user={user} baseNumber={baseNumber} txSuccess={txSuccess} setTxSuccess={setTxSuccess} />
    </div>
  )
}

export default Card
