import { Button, TextField } from '@material-ui/core';
import { React, useEffect, useState } from 'react';
import { rpc } from "../eosio/eos";
import './Member.css';
import MemberCard from './memberID.png';

// curl -X POST https://api.testnet.eos.io/v1/chain/get_table_rows -H "ContentType: application/json" -d '{
//   "json":true,"code": "dlpwaxmjysyi", "table": "meishi", "scope":"dlpwaxmjysyi", "lowerband": "lowerband"
// }'
function Member() {
  const [eosio, setEosio] = useState('Eosio')
  const [show, setShow] = useState(false)
  const [id, setId] = useState(0)

  const handleIdChange = (e) => {
    setId(e.target.value);
  };

  const handleShow = () => {
    setShow(true);
  }

  useEffect(() => {
    async function fetchEosio() {
      const result = await rpc.get_table_rows({
        json: true,
        code: 'dlpwaxmjysyi',
        table: 'meishi',
        scope: 'dlpwaxmjysyi',
      });
      try {
        setEosio(result.rows[id].hash_string);
      } catch (e) {
        console.error(e);
        setEosio(null);
      }
      return result;
    }
    fetchEosio();
  }, [id]);

  // const fetchEosio = () => {
  //   const request = axios.post('https://api.testnet.eos.io/v1/chain/get_table_rows',
  //     {
  //       json: true,
  //       code: 'dlpwaxmjysyi',
  //       tabel: 'meishi',
  //       scope: 'dlpwaxmjysyi'
  //     },
  //     {
  //       withCredentials: true,
  //     }
  //   );
  //   setEosio(request.data);
  // }

  return (
    <div className="Member">
      <img src={MemberCard} alt='member_img' className="member__img" />

      <TextField
        autoFocus
        margin="dense"
        label="id"
        type="string"
        value={id}
        onChange={handleIdChange}
      />
      <Button onClick={handleShow} className="button--submit">ブロックチェーン認証</Button>
      {/* TODO:会員証の認証方法について決める */}
      <h2>{show ? eosio : ''}</h2>
    </div>
  )
}

export default Member
