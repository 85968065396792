import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import { React, useState } from 'react';
import axios from "../auth/axios";
import requests from '../auth/requests';
import './Signup.css';



export default function Signup({ setToken, email, setEmail }) {
  const [open, setOpen] = useState(false);
  const [signupError, setSignupError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const loginToken = "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword1Change = (e) => {
    setPassword1(e.target.value);
  };

  const handlePassword2Change = (e) => {
    setPassword2(e.target.value);
  };

  const handleSignupError = () => {
    setSignupError(true);
  }

  const handlePasswordError = () => {
    // TODO: 入力中のパスワードのバリデーションを行いたい
    setPasswordError(true);
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      signupUser()
    }
  }

  const params = new URLSearchParams();

  const signupUser = () => {
    params.append('username', email)
    params.append('password', password1)
    if (password1 === password2) {
      axios.post(requests.signup, params)
        .then(response => {
          setToken([...loginToken, response.data["access_token"]]);
        })
        .catch(error => {
          handleSignupError();
        });
    } else {
      handlePasswordError();
    }
  }

  return (
    <div>
      <h2>SmartIDを始めよう</h2>
      <Button className="button--signup" onClick={handleClickOpen}>
        アカウント作成
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">SmartIDを始めよう</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="メールアドレス"
            type="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            margin="dense"
            label="パスワード"
            type="password"
            fullWidth
            value={password1}
            onChange={handlePassword1Change}
          />
          <TextField
            margin="dense"
            label="パスワード(確認用)"
            type="password"
            fullWidth
            value={password2}
            onChange={handlePassword2Change}
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button className="button--modal" onClick={signupUser} color="primary">
            サインアップ
          </Button>
        </DialogActions>
        {/* エラー処理 */}
        <Dialog open={signupError} onClick={() => { setSignupError(false); }}>
          <Alert variant="filled" severity="error">正しいメールアドレスとパスワードを入力してください。</Alert>
        </Dialog>
        <Dialog open={passwordError} onClick={() => { setPasswordError(false); }}>
          <Alert variant="filled" severity="error">パスワードが一致しているか確認してください。</Alert>
        </Dialog>

        {/* エラー処理 */}
      </Dialog>
    </div>
  );
}