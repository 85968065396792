import { Button } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import Alert from '@material-ui/lab/Alert';
import { React, useEffect, useState } from 'react';
import shajs from "sha.js";
import axios from "../auth/axios";
import requests from "../auth/requests";
import { api } from '../eosio/eos';
import './MakeCard.css';

function MakeCard({ user, baseNumber, txSuccess, setTxSuccess }) {
    const [id] = useState("");
    const [name] = useState("xdjunfatqydc");
    const [userName, setUserName] = useState(null);

    const userData = [
        "uuid",
        "user_id",
        "first_name",
        "last_name",
        "company_name",
        "department",
        "position",
        "postal_code",
        "company_address",
        "phone_number",
        "cellphone_number",
        "web_url",
        "business_email"
    ]

    const hashedUserData = shajs('sha256').update(JSON.stringify(user.meishi_base[baseNumber], userData)).digest('hex');

    const handleTxSuccess = () => {
        setTxSuccess(true);
    }

    const handleTxClose = () => {
        setTxSuccess(false);
    }

    async function submitMeishi() {
        try {
            // const hashedString = shajs('sha256').update(hashString).digest('hex');
            const result = await api.transact({
                actions: [{
                    account: 'dlpwaxmjysyi',
                    name: 'insert',
                    authorization: [{
                        actor: name,
                        permission: 'active',
                    }],
                    data: {
                        id: id,
                        user: name,
                        hash_string: hashedUserData,
                        user_name: userName,
                    },
                }]
            }, {
                blocksBehind: 3,
                expireSeconds: 30,
            });
            postTxIdToDB(result.transaction_id)
            handleTxSuccess();
        }
        catch (e) {
            console.error(e);
        }
    }

    async function postTxIdToDB(transaction_id) {
        const request = await axios.post(
            requests.makeCard + `${user.meishi_base[baseNumber].uuid}`,
            { eos_id: transaction_id },
        );
        return request
    }

    useEffect(() => {
        setUserName(shajs('sha256').update(user.meishi_base[baseNumber].uuid).digest('hex'));
    }, [baseNumber, user.meishi_base])

    return (
        <>
            <form className="form--meishi">
                <Button className="button--submit" onClick={submitMeishi}>
                    名刺発行
                </Button>
                {/* 成功処理 */}
                <Dialog open={txSuccess} onClose={handleTxClose} onClick={() => { setTxSuccess(false); }}>
                    <Alert variant="filled" severity="success">名刺が発行されました！</Alert>
                </Dialog>
                {/* 成功処理 */}
            </form>
        </>
    )
}

export default MakeCard
