const requests = {
  user: `/v1/users/`, // + email
  login: `/v1/auth/token`,
  logout: `/logout/`,
  signup: `/v1/auth/signup`,
  passwordReset: `/password/reset/`,
  passwordConfirm: `/password/reset/confirm/`,
  passwordChange: `/password/change/`,
  makeBase: `/v1/meishi/create/`, // + user_id
  makeCard: `/v1/meishi/create/card/`,
};

export default requests;
