import { Api, JsonRpc } from "eosjs";
import { JsSignatureProvider } from "eosjs/dist/eosjs-jssig";

// xdjunfatqydc TODO:アカウント名をどう発行するか

let privateKeys = [process.env.REACT_APP_KEY];
// PrivateKey.randomKey().then((privateKey) => privateKeys[0] = privateKey);

export const rpc = new JsonRpc("https://api.testnet.eos.io/");
export const signatureProvider = new JsSignatureProvider(privateKeys);
export const api = new Api({ rpc, signatureProvider });
