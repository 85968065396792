import { Button, TextField } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import React, { useState } from "react";
import axios from "../auth/axios";
import requests from "../auth/requests";
import "./MakeUser.css";

function MakeUser({ user }) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [department, setDepartment] = useState("");
    const [position, setPosition] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [cellphoneNumber, setCellphoneNumber] = useState("");
    const [webUrl, setWebUrl] = useState("");
    const [businessEmail, setBusinessEmail] = useState("");
    const [postSuccess, setPostSuccess] = useState(false);

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
    };

    const handleDeparmentChange = (e) => {
        setDepartment(e.target.value);
    };

    const handlePositionChange = (e) => {
        setPosition(e.target.value);
    };

    const handlePostalCodeChange = (e) => {
        setPostalCode(e.target.value);
    };

    const handleCompanyAddressChange = (e) => {
        setCompanyAddress(e.target.value);
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleCellphoneNumberChange = (e) => {
        setCellphoneNumber(e.target.value);
    };

    const handleWebUrlChange = (e) => {
        setWebUrl(e.target.value);
    };

    const handleBusinessEmailChange = (e) => {
        setBusinessEmail(e.target.value);
    };

    const handleSuccessClose = () => {
        setPostSuccess(false);
    }

    const makeBase = () => {
        axios.post(requests.makeBase + `${user.uuid}`, {
            first_name: firstName,
            last_name: lastName,
            company_name: companyName,
            department: department,
            position: position,
            postal_code: postalCode,
            company_address: companyName,
            phone_number: phoneNumber,
            cellphone_number: cellphoneNumber,
            web_url: webUrl,
            business_email: businessEmail,
        });
        setPostSuccess(true);
    };

    return (
        <>
            <div className="MakeUser">
                <DialogTitle id="form-dialog-title">新しい名刺情報を入力しよう</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        autoFocus
                        margin="dense"
                        label="first_name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="dense"
                        label="last_name"
                        value={lastName}
                        onChange={handleLastNameChange}
                    />
                    <TextField
                        margin="dense"
                        label="company_name"
                        variant="outlined"
                        value={companyName}
                        onChange={handleCompanyNameChange}
                    />
                    <TextField
                        margin="dense"
                        label="department"
                        variant="outlined"
                        value={department}
                        onChange={handleDeparmentChange}
                    />
                    <TextField
                        margin="dense"
                        label="position"
                        variant="outlined"
                        value={position}
                        onChange={handlePositionChange}
                    />
                    <TextField
                        margin="dense"
                        label="postal_code"
                        variant="outlined"
                        value={postalCode}
                        onChange={handlePostalCodeChange}
                    />
                    <TextField
                        margin="dense"
                        label="company_address"
                        variant="outlined"
                        value={companyAddress}
                        onChange={handleCompanyAddressChange}
                    />
                    <TextField
                        margin="dense"
                        label="phone_number"
                        variant="outlined"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                    />
                    <TextField
                        margin="dense"
                        label="cellphone_number"
                        variant="outlined"
                        value={cellphoneNumber}
                        onChange={handleCellphoneNumberChange}
                    />
                    <TextField
                        margin="dense"
                        label="web_url"
                        variant="outlined"
                        value={webUrl}
                        onChange={handleWebUrlChange}
                    />
                    <TextField
                        margin="dense"
                        label="business_email"
                        variant="outlined"
                        value={businessEmail}
                        onChange={handleBusinessEmailChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button className="button" onClick={makeBase}>
                        Submit
                    </Button>
                </DialogActions>
                {/* 成功処理 */}
                <Dialog open={postSuccess} onClose={handleSuccessClose}>
                    <Alert variant="filled" severity="success">名刺が発行されました！</Alert>
                </Dialog>
                {/* 成功処理 */}
            </div>
        </>
    );
}

export default MakeUser;
