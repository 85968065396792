import React from 'react';
import MakeUser from './MakeUser';

function User({ user }) {
    return (
        <>
            <MakeUser user={user} />
        </>
    )
}

export default User
