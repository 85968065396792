import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Alert } from '@material-ui/lab';
import { React, useState } from 'react';
import axios from '../auth/axios';
import requests from '../auth/requests';
import './Login.css';


export default function Login({ setToken, email, setEmail }) {
  const [open, setOpen] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [password, setPass] = useState('');
  var loginToken = "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handlePassChange = (e) => {
    setPass(e.target.value);
  }

  const handleLoginError = () => {
    setLoginError(true);
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      loginUser()
    }
  }

  const params = new URLSearchParams();

  const loginUser = () => {
    params.append('username', email)
    params.append('password', password)
    axios.post(requests.login, params)
      .then(response => {
        setToken([...loginToken, response.data["access_token"]]);
      })
      .catch(error => {
        handleLoginError();
      });
  }

  return (
    <div>
      <Button className="button--login" onClick={handleClickOpen}>
        ログイン
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">SmartIDにログイン</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="メールアドレス"
            type="email"
            fullWidth
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            margin="dense"
            label="パスワード"
            type="password"
            fullWidth
            value={password}
            onChange={handlePassChange}
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button href="https://www.google.com/">
            パスワードを忘れましたか?
          </Button>
          <Button className="button--modal" onClick={loginUser} color="primary">
            ログイン
          </Button>
        </DialogActions>
        {/* エラー処理 */}
        <Dialog open={loginError} onClick={() => { setLoginError(false); }}>
          <Alert variant="filled" severity="error">正しいメールアドレスとパスワードを入力してください。</Alert>
        </Dialog>
        {/* エラー処理 */}
      </Dialog>
    </div>
  );
}
