import { React } from 'react';
import Archive from './Archive';
import Card from './Card';
import CenteredTabs from './CenteredTabs';
import './Main.css';
import Member from './Member';


function Main({ user, baseNumber }) {
  return (
    <div className="main">
      <h2>{user.meishi_base[baseNumber].first_name} {user.meishi_base[baseNumber].last_name} さん、こんにちは。</h2>
      <div className="main__top">
        <CenteredTabs className="main__button" labels={['名刺', '会員証', '履歴']}>
          <Card user={user} baseNumber={baseNumber} />
          <Member />
          <Archive />
        </CenteredTabs>
      </div>
    </div >
  )
}

export default Main
